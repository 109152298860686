import { MAINTAIN_PERMISSION, PRINTLABEL_PERMISSION, CONTAINER_FILLING_MANUALLABEL } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Manual Label", "search-by": "ContainerCode,LabelFormat,ProdLineCode,ItemCode", "ph-search-by": "Search by keywords", "has-filter-inactive": false, "has-active": false, "actions-width": 112, "scroll": { x: 1600 } }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var del = ref.del;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return del(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.printlabel) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Print")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "printer", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.print(record);
    } } })], 1) : _vm._e(), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1)];
  } }]) }, [_c("url-field", { key: "ContainerCode", attrs: { "base-url": "/container-filling/manual-label", "data-index": "containerCode", "title": "Container No", "width": 125, "sorter": true, "fixed": "left" } }), _c("text-field", { key: "LabelFormat", attrs: { "data-index": "labelFormat", "title": "Label Format", "width": 125, "sorter": true } }), _c("text-field", { key: "DateTimeFill", attrs: { "data-index": "dateTimeFill", "title": "Date and Time", "width": 160 } }), _c("text-field", { key: "ProdLineCode", attrs: { "data-index": "prodLineCode", "title": "Production Line", "width": 140, "sorter": true } }), _c("text-field", { key: "ItemCode", attrs: { "data-index": "itemCode", "title": "Item Code", "width": 105, "sorter": true } }), _c("text-field", { key: "ItemDescription", attrs: { "data-index": "itemDescription", "title": "Item Code Description", "width": 220 } }), _c("text-field", { key: "LabelDesc1", attrs: { "data-index": "labelDesc1", "title": "Label Description 1" } }), _c("text-field", { key: "LabelDesc2", attrs: { "data-index": "labelDesc2", "title": "Label Description 2" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListManualLabel",
  inject: ["can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION,
        printlabel: PRINTLABEL_PERMISSION
      }
    };
  },
  methods: {
    print(record) {
      this.$router.push(`/container-filling/manual-label/print/${record.id}`);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListManualLabel = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-manual-label" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "cf.manual-labels", "create-route": "/container-filling/manual-label/create", "edit-route": "/container-filling/manual-label/:id", "api-url": _vm.apiUrl, "page": _vm.page } }, [_c("list-manual-label")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListManualLabel
  },
  data() {
    return {
      page: CONTAINER_FILLING_MANUALLABEL,
      ListManualLabel,
      apiUrl,
      itemsMenu: [
        {
          key: "containerprocessing",
          title: "Container Processing",
          path: ""
        },
        {
          key: "manuallabel",
          title: "Manual Label",
          path: "/container-filling/manual-label"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
